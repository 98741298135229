<template>
  <v-container class="align-center fill-height">

    <v-card>
      <v-img
        style="border-radius: 0"
        :src="require('@/assets/shhs_10yr_header.png')"
      ></v-img>
      <v-card
        style="padding: 20px"
      >

        <h2 class="text-h5">
          Buy your tickets for the reunion now
        </h2>
        Our 10 year (12 year?) reunion will be at Columbia View park in St. Helens on August 13th, 2022. Join us!
        <br>
        <v-btn
          color="primary"
          elevation="2"
          small
          :href="'https://www.eventbrite.com/e/360276686267'"
          target="_blank"
        >RSVP & Buy Tickets</v-btn>
        <br>
        <br>

        <v-divider></v-divider>

        <br>
        <h2 class="text-h5">
          Join the reunion mailing list
        </h2>
        For updates on our 2022 reunion and future reunions, register your email at the link below.
        <br>
        <v-btn
          color="primary"
          elevation="2"
          small
          @click="$router.push('register')"
        >Register</v-btn>
        <br>
        You can click the link below to see who has not provided contact info yet.
        <br>
        <v-btn
          color="primary"
          elevation="2"
          small
          @click="$router.push('alumni')"
        >Alumni List</v-btn>
        <br>

      </v-card>
    </v-card>

  </v-container>
</template>

<script>

export default {
  name: 'Landing',
  props: {
    msg: String
  },
  data: () => ({})
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  width: 55rem;
  max-width: 100%;
}

.v-card {
  width: 55rem;
  padding: 0px;
  text-align: left;
  border-radius: 1.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

</style>
